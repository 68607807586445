<template>
  <div>
    <div class="nav">
      <navTop></navTop>
    </div>
    <router-view/>

  </div>
</template>

<script>
  import navTop from '../components/navTop';

  export default {
    name: "index",
    components: {
      navTop
    },
    data() {
      return {};
    }
  };
</script>

<style lang="scss" scoped>
  .nav {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 10;
    background: #FFFFFF;
  }
</style>
